import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Construction } from "./components/Construction";
import { Login } from "./components/Login";

const AppRoutes = [
  {
    index: true,
    element: <Construction />
  },
  {
    path: "/login",
    element: <Login />
  }
];

export default AppRoutes;
