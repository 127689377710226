import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
  static displayName = Layout.name;

  componentDidMount () {
    this.setState({
        //background: require("../assets/images/initiative.png")
    });
  }

  render() {
    if (this.state)
      return (
        <div>
          <NavMenu />
          <div style={{backgroundPosition:"center center", backgroundSize:"cover", height:"100vh", /* backgroundImage:"url("+this.state.background+")" */ backgroundColor: "black", backgroundRepeat:"no-repeat"}}>
            <Container>
              {this.props.children}
            </Container>
          </div>
        </div>
      );
  }
}
