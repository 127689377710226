import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      userName: null,
      accessibleMenus: null
    };
  }

  componentDidMount() {
    this.validateToken();
    this.getAllRPGSystemsTree();
  }

  async validateToken() {
    let name = "userToken=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    let value = "";
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        value = c.substring(name.length, c.length);
      }
    }
    if (value)
    {
      console.log(value)
      await fetch('/api/UserAuth/UserTokenValidation/'+value)
      .then(res => {
        if (res.status == 200)
        {
          return res.text()
        }
        else {
          if (window.location.href.endsWith('/Login') == false)
          {
            alert("Veuillez vous reconnecter")
            window.location.replace("/Login");
          }
        }
      })
      .then(data => {
        this.setState({
          userName: data
        })
      })
    }
  }

  async getAllRPGSystemsTree() {
    await fetch('/api/Navigation/GetAllRPGSystemsTree/')
    .then(res => res.json())
    .then(data => {
      var foundSystems = []
      data.forEach((system, _) => {
        foundSystems.push(system.Name)
      });
      this.setState({
        accessibleMenus: foundSystems
      })
    });
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">L'initiative</NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              {
                this.state.accessibleMenus != null ? (
                  this.state.accessibleMenus.map((system, index) => {
                    return (
                      <NavItem>
                        <NavLink tag={Link} className="text-dark" to={`/Systems/${system}`} state={{data: system}}>{system}</NavLink>
                      </NavItem>
                    )
                  })
                ) : (
                  <></>
                )
              }
              { this.state.userName == null ? (
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/Login">Se connecter</NavLink>
                </NavItem>
                ) : (
                <NavItem><p className='nav-link'>Connecté·e en tant que {this.state.userName}</p></NavItem>
                )
              }
              {/* <NavItem>
                <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
              </NavItem> */}
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
