import { Box } from '@mui/material';
import React, { Component } from 'react'

export class Construction extends Component {
    static displayName = Construction.name;
    
    Construction() {
    }

    render () {
        return (
            <Box>
                <div>
                    <div style={{textAlign:"center", color:"white", height:"100vh"}}>
                        <h1>Bienvenue sur l'Initiative</h1>
                        <p>Ce site a pour but de regrouper les ressources de JdR de notre magnifique serveur Discord de rôlistes.</p>
                        <p>Merci de contacter Kévin - RedFox pour tout problème rencontré !</p>
                    </div>
                </div>
            </Box>
        )
    }
}