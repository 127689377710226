import React, { Component, useEffect } from 'react';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import cyberpunk from '../../assets/css/cyberpunk.module.css'
import pathfinder from '../../assets/css/pathfinder.module.css'

const RPGSystem = () =>
{
    const { system } = useParams();
    const [styles, setStyles] = useState("");

    useEffect(() => {
        switch (system)
        {
            case 'Cyberpunk RED':
                setStyles(cyberpunk)
                break;
            case 'D&D 5E':
                break;
            case 'Vampire V5':
                break;
            default:
                setStyles(pathfinder)
                break;
        }
    }, [system])

    return (
            <>
            {
                styles == null ? (<></>) : (<h1 className={styles.head}>{system}</h1>)
            }
            </>
        )
}

export default RPGSystem;