import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { Construction } from './components/Construction'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Login } from './components/Login';
import RPGSystem from './components/RPGSystems/RPGSystem';

export default class App extends Component {
  static displayName = App.name;

  

  render() {
      return (
        <GoogleOAuthProvider clientId="191575178267-bu7a5l70vgbtnc5pj99fpvc10ks2bc8h.apps.googleusercontent.com">
          <Layout>
            <Routes>
                <Route key="/" element={<Construction />} path="/"/>;
                <Route key="/Login" element={<Login />} path="/Login"/>;
                <Route key="/Systems/Dynamic" element={<RPGSystem />} path="/Systems/:system"/>;
            </Routes>
          </Layout>
        </GoogleOAuthProvider>
      );
  }
}
