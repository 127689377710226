import React, { useEffect, useState } from "react";
import { GoogleLogin } from '@react-oauth/google';
import { useJwt } from "react-jwt";
import { Container, Box } from "@mui/material";
import { MarginOutlined } from "@mui/icons-material";

export function Login() {
  const [token, setToken] = useState("")
  const { decodedToken, isExpired, reEvaluateToken } = useJwt(token);

  const updateToken = (credentialResponseToken) => {
    reEvaluateToken(credentialResponseToken)
  }

  useEffect(() => {
    if (decodedToken !== null)
    {
      getAuthFromServer();
    }
  }, [decodedToken])

  async function getAuthFromServer() {
    await fetch("api/UserAuth/UserOAuth", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'OAuthToken': token.credential,
        'Surname': decodedToken.given_name,
        'Name': decodedToken.family_name,
        'Email': decodedToken.email
      })
    }).then(res => res.json())
    .then(data => {
      if (data === null) {
        alert("Ce compte ( "+decodedToken.email+" ) n'est pas autorisé à se connecter.")
      }
      else {
        const d = new Date();
        d.setTime(d.getTime() + (2*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = "userToken" + "=" + token.credential + ";" + expires + ";path=/";
        window.location.replace("/");
      }
    })
  }

  return (
      <Box style={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:"20vh",
        width: "30vh",
        backgroundColor:"white",
        position: "absolute",
        marginTop:"20vh",
        marginLeft:"50vh",
        borderRadius:"5px",
        border:"2px solid rgba(30, 30, 30, 0.1)"
      }}>
        <GoogleLogin
          onSuccess={credentialResponse => {
          setToken(credentialResponse)
          updateToken(credentialResponse.credential)
          }}
          onError={() => {
            console.log('Login Failed');
           }}
        />
      </Box>
  );
}